import { getRequest } from "@components/config/axiosClient";
import { hireSaving, updatePrices, resetToken, sectionCounter, getProduct } from "@components/config/requests";
import CacheFactory from "@components/Factories/CacheFactory";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../useAuth";
import useGeneralActions from "../useGeneralActions";
import useCartDispatch from "./useCartDispatch";


const initialState = {

};

const useCartHandler = () => {
    const dispatch = useDispatch();
    const { cart, cartUpdating, updatingPrices, retrievingQuote, addonProduct } = useSelector((state) => state.cartManagementReducer);
    const { details } = useSelector((state) => state.checkoutDetailsReducer);
    const {isAuthenticated, getUser} = useAuth();

    const {
        RESET_CART,
        UPDATE_CART,
        UPDATE_CART_COUNTER,
        SET_TOKEN,
        SET_TOP_CART_MODAL,
        SET_CART_LOADER,
        SET_PRICES_LOADER,
        UPDATE_CART_KEY,
        RETRIEVE_QUOTE_LOADER,
        UPDATE_CART_INDEX,
    } = useCartDispatch();

    const {setPageSpinner} = useGeneralActions();

    const [localQty, setLocalQty] = useState(1);
    const [checkForAddons, setCheckForAddons] = useState(false);

    const [localCart, setLocalCart] = useState({
        content: [],
        totals: {},
        hireSaving: {},
        addonProducts: null,
        VATFlag: true,
        counter: 0,
        cartChanged: false
    });

    useEffect(() => {
        setLocalCart(cart);
    }, [cart])

    useEffect(() => {
        if(checkForAddons && !cartUpdating && !updatingPrices){
       
        // cart.addonProduct.forEach(function(addon){
        //     if (addon.productId){
        //         addToCart(addon.productId,addon.slug,parseInt(localQty), null, null, null, addon.source);
        //     }
        // });
      }
    
      return () => {
      }
    }, [checkForAddons, cartUpdating, updatingPrices])

    


    // REDUX ACTIONS




    // FUNCTIONS

    const createCartCopy = (cartCopy = cart) => {
        return structuredClone(cartCopy);
    }

    const updateCartState = (newCart, source) => {
        if(source && (source == 'planner' || source == 'complementary')){
            updateCart(newCart, 'site');
        }
        else {
            updateCart(newCart, 'site')
        }
    }

    const checkForPadProduct = (newCart, padProduct, qty, item, foundItemIndex) => {
        let cartCopy = createCartCopy(newCart);

        const foundPadIndex = item.pads.findIndex(pad => pad.id == padProduct.id);
        const padExists = foundPadIndex != -1;


        if(padExists){
            const padItem = cartCopy.content[foundItemIndex].pads[foundPadIndex];
            cartCopy.content[foundItemIndex].pads[foundPadIndex].qty = parseInt(padItem.qty) + parseInt(qty);
        }
        else {
            const padPrice = parseFloat(padProduct.data.price).toFixed(2)
            const subtotal = parseFloat(parseInt(qty) * parseFloat(padProduct.data.price)).toFixed(2)
            const prevPads = cartCopy.content[foundItemIndex].pads;
            const newPad = [{
                ...padProduct,
                qty: parseInt(qty),
                price: padPrice,
                subtotal: subtotal,
            }]
            cartCopy.content[foundItemIndex].pads = [...prevPads, ...newPad];
        }

        UPDATE_CART_KEY('content', cartCopy.content);

        return {
            pad_exists: padExists,
            cartCopy: cartCopy
        }
    }

    const handleAddonProducts = (newCart, product, qty, source) => {
        let cartCopy = createCartCopy(newCart);

        if((typeof product.addons !== 'undefined') || (product.addons.length > 0)){
            cartCopy.addonProducts = {
                qty: qty,
                product: product,
                addons: []
            };

            product.addons.forEach((prod) => {
                cartCopy.addonProducts.addons.push(prod)
            });
        }
        else {
            cartCopy.addonProducts.addons = [];
        }

        UPDATE_CART_KEY('addon_products', cartCopy?.addon_products || [])

        return cartCopy;
    }


    const sortAddonProduct = async (newContent, addon) => {
        let contentCopy = structuredClone(newContent);

        const newItem = {
            id: addon.productId
        }

        const foundItemIndex = newContent.findIndex((item) => item.id == newItem.id)
        const exists = foundItemIndex !== -1;

        if(exists){
            contentCopy[foundItemIndex].qty = parseInt(contentCopy[foundItemIndex].qty) + parseInt(localQty);
        }

        else if(!exists){
            const measurements = {
                height: 60,
                width: 60
            }

            const productReq = await getProduct(addon.slug, measurements.height, measurements.width);
            const product = productReq.data;
        
            contentCopy.push({
                id: addon.productId,
                data: product,
                qty: parseInt(localQty),
                price: parseFloat(product.price),
                subtotal: parseInt(localQty) * parseFloat(product.price),
                bow: null,
                pad: null,
                color: null,
                pads: []
            });

            
            // ADD PADD COUNT
            // UPDATE_CART_KEY('content', contentCopy);

        }

        return contentCopy;


    }

    const addToCart = async (productID, slug, qty = 1, bow, pad, color, source, section_id = 4, padProduct = null) => {
        setLocalQty(qty);
        //if a pad is required and no pad is selected we return as it will prompt user to add pad.
        if(pad == 'yes' && padProduct == null) return;
    
            sectionCounter(section_id);
            SET_CART_LOADER(true);
            SET_PRICES_LOADER(true);

            if(!qty){
                qty = 1;
            }

            const actual_source = typeof source == 'undefined' ? false : source;
        
            let cartCopy = createCartCopy();

            const newItem = {
                id: productID,
                bow: bow,
                pad: pad,
                color: color
            }

            const foundItemIndex = cartCopy.content.findIndex((item) => item.id == newItem.id)

            // -1 means it does not exist.
            const exists = foundItemIndex !== -1;

            if(source == 'complementary'){
                SET_TOP_CART_MODAL(false);
            }
            else {
                SET_TOP_CART_MODAL(true, 'cart', "Item added. You're only 2 clicks away from getting a quote!");
                SET_CART_LOADER(false);
            }

            
            if(exists){
                cartCopy.content[foundItemIndex].qty = parseInt(cartCopy.content[foundItemIndex].qty) + parseInt(qty);
                const product = cartCopy.content[foundItemIndex];
                cartCopy = handleAddonProducts(cartCopy, product.data, qty, actual_source);

                if(padProduct != null){
                    const checkForPad = checkForPadProduct(cartCopy, padProduct, qty, product, foundItemIndex);
                    cartCopy = checkForPad.cartCopy;
                }
                cartCopy.counter = updateCartCounter(cartCopy)
            }

            else if(!exists){
                const measurements = {
                    height: 60,
                    width: 60
                }

                const productReq = await getProduct(slug, measurements.height, measurements.width);
                const product = productReq.data;


                let pads = [];
                if(padProduct) {
                    const padPrice = parseFloat(padProduct.data.price).toFixed(2)
                    const subtotal = parseFloat(parseInt(qty) * parseFloat(padProduct.data.price)).toFixed(2)
                    pads = [
                        {
                            ...padProduct,
                            qty: parseInt(qty),
                            price: padPrice,
                            subtotal: subtotal,
                        }
                    ]
                }

                const price = parseFloat(product?.all_in_price ? product.all_in_price : product.price);

                cartCopy.content.push({
                    id: productID,
                    data: product,
                    qty: parseInt(qty),
                    price: parseFloat(price),
                    subtotal: parseInt(qty) * parseFloat(price),
                    bow: bow,
                    pad: pad,
                    color: color,
                    pads: pads
                });

                
                // ADD PADD COUNT
                UPDATE_CART_KEY('content', cartCopy.content);
                cartCopy.counter = updateCartCounter(cartCopy);
                cartCopy = handleAddonProducts(cartCopy, product, qty, actual_source);

            
            }

      

            let contentCopy = cartCopy.content;
            for (const addon of cart.addonProduct) {
                if (addon.productId) {
                    contentCopy = await sortAddonProduct(contentCopy, addon);
                    UPDATE_CART_KEY('content', contentCopy);
                }
            }

            cartCopy.content = contentCopy;
            
            updateCartState(cartCopy);
       
    }

    const setCart = (content) => {
        let cartCopy = createCartCopy();
        cartCopy.content = content;
        updateTotals(cartCopy, 'site')
    }

    const getCart = () => {
        // return CacheFactory.get('cart');
        return cart;
    }

    const removeFromCart = async (mainIndex, typeIndex = null, type, source) => {
        SET_PRICES_LOADER(true);
        let cartCopy = createCartCopy();
        if(typeIndex != null){
            const extraProduct = cartCopy.content[mainIndex][type]
            if(extraProduct.length == 1){
                return removeFromCart(mainIndex);
            }
            else {
                cartCopy.content[mainIndex].qty = parseInt(cartCopy.content[mainIndex].qty) - parseInt(extraProduct[typeIndex].qty)
                cartCopy.content[mainIndex][type].splice(typeIndex, 1);
                UPDATE_CART(cartCopy);
                cartCopy.counter = updateCartCounter(cartCopy);
                updateCart(cartCopy, source);
            }
        }
        else {
            cartCopy.content.splice(mainIndex, 1);
            if (cartCopy.content.length != 0) {
                UPDATE_CART(cartCopy);
                cartCopy.counter = updateCartCounter(cartCopy);
                updateCart(cartCopy, source);
            } else {
                SET_TOP_CART_MODAL(false);
                clearCart();
            }
        }
        
    }

    const updateTotals = (newCart, source, type) => {
        // cartchanged =  true
        let cartCopy = createCartCopy(newCart);
        
        if(source != 'checkout'){
            cartCopy.cartChanged = true;
        }

        if(source == 'checkout' && type){
            // get cache
        }


        cartCopy.totals.productTotal = 0;
        cartCopy.content.forEach((item, key) => {
            if(!CacheFactory.has('hireWeeks')){
                item.subtotal = item.qty * item.price;
            }
            else {
                item.subtotal = item.qty * item.price * CacheFactory.get('hireWeeks');   
            }

            cartCopy.totals.productTotal += item.subtotal;
        });


        cartCopy.totals.productTotal = parseFloat(cartCopy.totals.productTotal);
        UPDATE_CART_KEY('totals', cartCopy.totals);
        // TODO: dispatch for totals
        

        // getHireSaving
        getHireSaving(cartCopy, source);

        // updateTotals

    }

    const updateCartCounter = (newCart = cart) => {
        const totalQuantity = newCart.content.reduce((totalQty, item) => {
            let itemQty = parseInt(item.qty, 10);
    
            if (item.pads && Array.isArray(item.pads)) {
                let padsQty = item.pads.reduce((subTotalQty, pad) => {
                    return subTotalQty + parseInt(pad.qty, 10);
                }, 0);
    
                itemQty += padsQty;
            }
            return totalQty + itemQty;
        }, 0);

        UPDATE_CART_COUNTER(totalQuantity)
        return totalQuantity;
    }

    const changeProductQty = (idx, padIdx = null, qty, setQtyUpdating = false) => {
        let cartCopy = createCartCopy();
        const product = cartCopy.content[idx];
    
        SET_PRICES_LOADER(true);
    
        if (!validateQty(qty)) return;

    
        const updateProductAndPadQty = (productQty, padQty = null) => {
            cartCopy.content[idx].qty = productQty;
            if (padQty !== null) {
                const correctPadIdx = padIdx == null ? 0 : padIdx;
                cartCopy.content[idx].pads[correctPadIdx].qty = padQty;
            }
        };
    
        if (padIdx != null) {
            // Update specific pad quantity
            const pad = product.pads[padIdx];
            const difference = qty - pad.qty;
            updateProductAndPadQty(product.qty + difference, qty);
        } else if (product.pads && product.pads.length > 0) {
            // Update product quantity and adjust the first pad quantity if no specific pad index is provided
            const firstPad = product.pads[0];
            const totalDifference = qty - product.qty;
            const newFirstPadQty = firstPad.qty + totalDifference;
            updateProductAndPadQty(qty, newFirstPadQty);
        } else {
            // Update product quantity without pad adjustments
            updateProductAndPadQty(qty);
        }

        if(setQtyUpdating && typeof setQtyUpdating == 'function'){
            setQtyUpdating({
                product: null,
                padIdx: null
            })
        }
    
        cartCopy.counter = updateCartCounter(cartCopy);
        updateCart(cartCopy, 'checkout');
    };


    const updateCache = (newCart, source) => {
        CacheFactory.put('cart', newCart);
        if (source == 'modifiers') {
            // helper.hideLoader();
        } else if (source == 'site') {
            // $rootScope.$broadcast('cartUpdated', 'yes');
            // helper.hideLoader();
        } else {
            // $rootScope.$broadcast('cartUpdated', 'yes');
        }
        // setLocalCart(newCart);
        UPDATE_CART(newCart);

        setCheckForAddons((prevState) => !prevState);

        if(!cartUpdating) SET_CART_LOADER(false);
        if(!updatingPrices) SET_PRICES_LOADER(false);
        if(!retrievingQuote) RETRIEVE_QUOTE_LOADER(false);

    }

    const validateQty = (qty) => {
		if (Math.floor(qty) == qty && !isNaN(qty) && qty > 0) {
            return true;
        } else {
            return false;
        }
    }

    const incProductQty = (idx) => {
        let cartCopy = createCartCopy();
        const product = cartCopy.content[idx];
        if(product.qty > 1){
            newCart.content[idx] = { ...product, qty: product.qty + 1 };
            updateCartCounter(cartCopy);
            updateCart(cartCopy, 'checkout')
        }
    }

    const decProductQty = (idx) => {
        let cartCopy = createCartCopy();
        const product = cartCopy.content[idx];
        if(product.qty > 1){
            newCart.content[idx] = { ...product, qty: product.qty - 1 };
            updateCartCounter(cartCopy);
            updateCart(cartCopy, 'checkout')
        }
    }

    const updateCart = async (newCart, source, type) => {

        let cartCopy = createCartCopy(newCart);
        const products = [];
        if(source == 'checkout' && type){
            // CacheFactory.get('cart')
        }

        cartCopy.content.forEach((item, key) => {
            products.push({
                id: item.data.product_id,
                qty: item.qty
            });
        });

        let data = {
            'products': products,
            'hire-days': (CacheFactory.has('hireDays') ? CacheFactory.get('hireDays') : 8),
            'brand': process.env.brand.brand_key,
            'include_all_in': true
        };

        if(isAuthenticated){
            const user = getUser();
            if(user?.details != null && Object.keys(user.details).length > 0){
                const userDetails = user.details;

                const discount = userDetails?.discounts?.find((discount) => discount.user_account_discount_type_id == 1 || discount.user_account_discount_slug == 'product-price')?.discount_value || false;
                if(discount){
                    data.discount_multiplier = discount;
                }
            }
        }

        if(details?.deliveryDate && details.deliveryDate != ""){
            data.deliveryDate = details.deliveryDate;
        }
        if(details?.collectionDate && details.collectionDate != ""){
            data.collectionDate = details.collectionDate;
        }

        const product_prices = await updatePrices(data);
        cartCopy.content.forEach((item, key) => {
            item.price = parseFloat(product_prices.data[item.data.product_id]);
        })

        UPDATE_CART_KEY('content', cartCopy.content);
        SET_CART_LOADER(false);
        updateTotals(cartCopy, source, type)


    }

    const updateCartHttp = async (source) => {
        let cartCopy = createCartCopy()
        const products = [];;

        cartCopy.content.forEach((item, key) => {
            products.push({
                id: item.data.product_id,
                qty: item.qty
            });
        });

        const data = {
            'products': products,
            'hire-days': (CacheFactory.has('hireDays') ? CacheFactory.get('hireDays') : 8),
            'brand': process.env.brand.brand_key
        };

        const product_prices = await updatePrices(data);
        return product_prices;
      
    }

    const clearCart = async () => {
        SET_CART_LOADER(true);
        RESET_CART();
        CacheFactory.clear();

        const request = await resetToken();
        const response = request.data;
        CacheFactory.put('token', response)
      
        SET_TOKEN(response);
        CacheFactory.put('hireDays', 8);
        CacheFactory.put('hireWeeks', 1); //empty the cart cache

      
        SET_CART_LOADER(false);
    }

    const getTotalWeight = (currentCart = cart.content) => {
        const weight = currentCart.reduce((totalWeight, item) => {
            let itemWeight = parseFloat(item.data.weight) * parseInt(item.qty);
            if (item?.pads && item?.pads.length !== 0) {
                let padsWeight = item.pads.reduce((padTotalWeight, subItem) => {
                    return padTotalWeight + (parseFloat(subItem.data.weight) * parseInt(subItem.qty));
                }, 0);
    
                itemWeight += padsWeight; // Uncomment this line if you want to add padsWeight to itemWeight
                // console.log(padsWeight);
            }
            return (totalWeight + itemWeight);
        }, 0);


        return weight;
    }

    const getHireSaving = async (newCart, source, type) => {
        let cartCopy = createCartCopy(newCart);
        const data = {
            product_total: newCart.totals.productTotal,
            hireDays: CacheFactory.get('hireDays'),
        }

        const hireSavingReq = await hireSaving(data);
        cartCopy.hireSaving = {
            value: hireSavingReq.data.value,
            percentage: hireSavingReq.data.percentage,
        }

        UPDATE_CART_KEY('hireSaving', cartCopy.hireSaving);
        cartCopy.totalWeight = getTotalWeight(cartCopy.content);

        UPDATE_CART_KEY('totalWeight', cartCopy.totalWeight);
        updateCache(cartCopy, source)

    }

    const updateCartProductKey = async (index, typeIndex = null, data, content = cart.content, update = false, baseCart = cart) => {

        let cartCopy = structuredClone(baseCart);
        let contentCopy = structuredClone(content);
        // let cartCopy = structuredClone(cart);
        // cartCopy.content = await structuredClone(content);
        // cartCopy.content[index] = data;
        
        // console.log(cartCopy.content, content)
        if(typeIndex == null){
            cartCopy.content[index] = data;
            contentCopy[index] = data;
        }
        else {
            cartCopy.content = contentCopy;
        }

        UPDATE_CART_KEY('content', contentCopy);

        if(update){
            updateTotals(cartCopy, 'checkout')
        }

        return await data;
    }


    const completeQuoteFromMessage = (messageID, token) => {

    }

    const switchVAT = () => {

    }

    const setAddonProducts = (productsArray) => {
        let cartCopy = createCartCopy();
        cartCopy.addonProducts = productsArray;
        updateCache(productsArray);
    }

    const getAddonProducts = () => {
        return cart.addonProducts.addons;
    }

    const getAddonProductQty = () => {
        return cart.addonProducts.qty;
    }



    
    return {
        dispatch: {
            RESET_CART,
            UPDATE_CART
        },
        addToCart,
        setCart,
        getCart,
        removeFromCart,
        updateTotals,
        updateCartCounter,
        changeProductQty,
        updateCache,
        validateQty,
        incProductQty,
        decProductQty,
        updateCart,
        updateCartHttp,
        clearCart,
        getTotalWeight,
        getHireSaving,
        completeQuoteFromMessage,
        switchVAT,
        setAddonProducts,
        getAddonProducts,
        getAddonProductQty,
        updateCartProductKey
    }
}

export default useCartHandler;